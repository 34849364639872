<template>
    <main class="c-app-content is-sidebar-tiny">
        <div class="row">
            <div class="col-md-12">
                <div class="card title">
                    <div class="card-body title-filter">
                        <div class="row">
                            <div class="col-lg-12 title-filte-right">
                                <b-tabs class="c-tabs-button mr-3" small pills>
                                    <b-tab v-for="parent in parents" :key="parent.id" :title="parent.name" :active="parent.id == filterFormModel.parent_id" @click='setTab(parent.type, parent.id)'></b-tab>
                                </b-tabs>

                                <time-range-picker  class="pr-1" :model="filterFormModel" label="Thời gian" :defaultShortcut="10" @change="load" />

                                <dropdown class="mr-1" :model="filterFormModel" attribute="group" :right-menu="true"
                                          type="bordered" icon="fa fa-list" :options="groupOptions" @change="loadGroup">
                                </dropdown>

                                <province-select v-if="filterFormModel.group == 'province_id'" class="mr-1" :model="filterFormModel" attribute="cols" :filter="{ids: $store.state.client.province_id}" :isFilter="true" :multiple="true" @change="load" />
                                <form-select-multiple v-else :close-on-select='false' class="mr-1"  :disabled="filterFormModel.group == 'all'" 
                                                      :model="filterFormModel" attribute="cols" @change="load" 
                                                      :options="colOptions" placeHolder="Chọn tiêu chí" />

                                <button :disabled='listLoading' class="btn btn-gray btn-sm mr-1" @click="load">
                                    <b-spinner v-if="firstLoading || listLoading" small></b-spinner>
                                    <i v-else class="fa fa-sync-alt" ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row chartbody" >
            <div class="col-md-8">
                <div class="card title">
                    <div class="card-body">
                        <div class="row" style="justify-content:center !important">
                            <div class="text-center">
                                <div class="title-filter-title mb-2">
                                    <b v-if="filterFormModel.group === 'all'" >Số người hưởng lợi so với chỉ tiêu (theo từng loại hình dịch vụ)</b>
                                    <b v-else>Số người hưởng lợi (theo từng loại hình dịch vụ)</b>
                                </div>
                            </div>
                        </div>
                        <div v-if="chartLoading1" class="mt-3 p-1">
                            <g-loading />
                        </div>
                        <div v-else>
                            <apexchart height="300" :options="chartOptions1" :series="chartData1"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="row" style="justify-content:center !important">
                            <div class="text-center">
                                <div class="title-filter-title mb-2">
                                    <b>
                                        Số cơ sở PHCN áp dụng đa chuyên ngành <span v-if="filterFormModel.group === 'all'">so với chỉ tiêu</span>
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div v-if="['gender', 'type', 'degree_disability', 'agent_orange_victim', 'year_old'].includes(filterFormModel.group)" class='text-danger text-center' >
                            Không có dữ liệu
                        </div>
                        <div v-else-if="chartLoading2" class="mt-3 p-1">
                            <g-loading />
                        </div>
                        <div v-else>
                            <apexchart height="300" :options="chartOptions2" :series="chartData2"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row chartbody">
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="row" style="justify-content:center !important">
                            <div class="text-center">
                                <div class="title-filter-title mb-2">
                                    <b>
                                        Số cán bộ PHCN được đào tạo <span v-if="filterFormModel.group === 'all'">so với chỉ tiêu</span>
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div v-if="['type', 'degree_disability', 'agent_orange_victim'].includes(filterFormModel.group)" class='text-danger text-center' >
                            Không có dữ liệu
                        </div>
                        <div v-else-if="chartLoading3" class="mt-3 p-1">
                            <g-loading />
                        </div>
                        <div v-else>
                            <apexchart height="300" :options="chartOptions3" :series="chartData3"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="row" style="justify-content:center !important">
                            <div class="text-center">
                                <div class="title-filter-title mb-2">
                                    <b>
                                        Số người chăm sóc được đào tạo <span v-if="filterFormModel.group === 'all' && !from">so với chỉ tiêu</span>
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div v-if="['type', 'degree_disability', 'agent_orange_victim'].includes(filterFormModel.group)" class='text-danger text-center' >
                            Không có dữ liệu
                        </div>
                        <div v-else-if="chartLoading4" class="mt-3 p-1">
                            <g-loading />
                        </div>
                        <div v-else>
                            <apexchart height="300" :options="chartOptions4" :series="chartData4"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="row" style="justify-content:center !important">
                            <div class="text-center">
                                <div class="title-filter-title mb-2">
                                    <b>
                                        Số người khuyết tật tham gia hoạt động hội nhóm <span v-if="filterFormModel.group === 'all'">so với chỉ tiêu</span>
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div v-if="['type', 'degree_disability', 'agent_orange_victim'].includes(filterFormModel.group)" class='text-danger text-center' >
                            Không có dữ liệu
                        </div>
                        <div v-else-if="chartLoading5" class="mt-3 p-1">
                            <g-loading />
                        </div>
                        <div v-else>
                            <apexchart height="300"  :options="chartOptions5" :series="chartData5"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row tablebody">
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="title-filter-title mb-2"><b>Tỷ lệ người hưởng lợi có cải thiện chất lượng sống</b></div>
                        <div class="title-template-table table-responsive">
                            <div v-if="chartLoading6" class="mt-3 p-1">
                                <g-loading />
                            </div>
                            <table v-else class="table table-hover table-sm table-head-fixed table-bordered">
                                <thead>
                                    <tr>
                                        <th v-if="filterFormModel.group !== 'all'" >{{getLabelTable()}}</th>
                                        <th>Số người có cải thiện</th>
                                        <th>Số người được đo lường</th>
                                        <th>Tỷ lệ</th>
                                    </tr>
                                </thead>
                                <tbody v-if="items6.length === 0">
                                    <tr>
                                        <td :colspan="filterFormModel.group !== 'all' ? 4: 3" class="text-danger text-center">Không có dữ liệu</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr v-for="item in items6" :key="item.label" >
                                        <td v-if="filterFormModel.group !== 'all'">{{item.label}}</td>
                                        <td class="text-center">{{item.tu}}</td>
                                        <td class="text-center">{{item.mau}}</td>
                                        <td class="text-center">{{item.per}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="title-filter-title mb-2"><b>Tỷ lệ người khuyết tật có cải thiện chức năng sinh hoạt hàng ngày (ADL)</b></div>
                        <div class="title-template-table table-responsive">
                            <div v-if="chartLoading7" class="mt-3 p-1">
                                <g-loading />
                            </div>
                            <table v-else class="table table-hover table-sm table-head-fixed table-bordered">
                                <thead>
                                    <tr>
                                        <th v-if="filterFormModel.group !== 'all'" >{{getLabelTable()}}</th>
                                        <th>Số người có cải thiện</th>
                                        <th>Số người được đo lường</th>
                                        <th>Tỷ lệ</th>
                                    </tr>
                                </thead>
                                <tbody v-if="items7.length === 0">
                                    <tr>
                                        <td :colspan="filterFormModel.group !== 'all' ? 4: 3" class="text-danger text-center">Không có dữ liệu</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr v-for="item in items7" :key="item.label" >
                                        <td v-if="filterFormModel.group !== 'all'">{{item.label}}</td>
                                        <td class="text-center">{{item.tu}}</td>
                                        <td class="text-center">{{item.mau}}</td>
                                        <td class="text-center">{{item.per}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card title">
                    <div class="card-body">
                        <div class="title-filter-title mb-2"><b>Tỷ lệ cải thiện số đo kết quả chăm sóc</b></div>
                        <div class="title-template-table table-responsive">
                            <div v-if="chartLoading8" class="mt-3 p-1">
                                <g-loading />
                            </div>
                            <table v-else class="table table-hover table-sm table-head-fixed table-bordered">
                                <thead>
                                    <tr>
                                        <th v-if="filterFormModel.group !== 'all'" >{{getLabelTable()}}</th>
                                        <th>Số người có cải thiện</th>
                                        <th>Số người được đo lường</th>
                                        <th>Tỷ lệ</th>
                                    </tr>
                                </thead>
                                <tbody v-if="items8.length === 0">
                                    <tr>
                                        <td :colspan="filterFormModel.group !== 'all' ? 4: 3" class="text-danger text-center">Không có dữ liệu</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr v-for="item in items8" :key="item.label" >
                                        <td v-if="filterFormModel.group !== 'all'">{{item.label}}</td>
                                        <td class="text-center">{{item.tu}}</td>
                                        <td class="text-center">{{item.mau}}</td>
                                        <td class="text-center">{{item.per}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style>
    .chartbody .card.title {
        height: 400px;
    }

    .tablebody .title-filter-title {
        /*font-size: 10px;*/
    }
    .tablebody .card.title {
        min-height: 200px;
    }
</style>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import moment from 'moment';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ProvinceSelect from '@/components/ProvinceSelect';

    export default {
        mixins: [role],
        components: {
            Dropdown,
            ProvinceSelect,
            apexchart: VueApexCharts
        },
        data: function () {
            return {
                colorFixed: ['#4b7faf', '#5a9bd5', '#adc6e5'],
                firstLoading: true,
                listLoading: false,
                loading: true,
                service: '/dashboard-fixed',
                parents: [],
                kpis: [],
                filterFormModel: {
                    parent_id: null,
                    from: null,
                    to: null,
                    group: "all",
                    cols: []
                },
                colOptions: [],
                groupOptions: [
                    {value: 'all', text: "Phân nhóm: Tất cả"},
                    {value: 'province_id', text: "Theo tỉnh/thành phố"},
                    {value: 'gender', text: "Theo giới tính"},
                    {value: 'type', text: "Theo dạng tật"},
                    {value: 'degree_disability', text: "Theo mức độ khuyết tật"},
                    {value: 'agent_orange_victim', text: "Theo nạn nhân da cam"},
                    {value: 'year_old', text: "Theo người lớn/trẻ em"}
                ],
                chartLoading1: true,
                chartOptions1: {chart: {type: 'bar'}},
                chartData1: [],
                chartLoading2: true,
                chartOptions2: {chart: {type: 'bar'}},
                chartData2: [],
                chartLoading3: true,
                chartOptions3: {chart: {type: 'bar'}},
                chartData3: [],
                chartLoading4: true,
                chartOptions4: {chart: {type: 'bar'}},
                chartData4: [],
                chartLoading5: true,
                chartOptions5: {chart: {type: 'bar'}},
                chartData5: [],
                chartLoading6: true,
                items6: [],
                chartLoading7: true,
                items7: [],
                chartLoading8: true,
                items8: [],
                colors: ['#32CD32', '#FFA500']
            };
        },
        watch: {
            "filterFormModel.group": {
                handler(val) {
                    this.colOptions = [];
                    if (val) {
                        switch (val) {
                            case "gender":
                            {
                                let options = this.$params.gender;
                                this.colOptions = options.filter(option => option.value !== null);
                                break;
                            }
                            case "type":
                            {
                                let options = this.$params.patient.typeOptions;
                                this.colOptions = options.filter(option => option.value !== null);
                                break;
                            }
                            case "degree_disability":
                            {
                                let options = this.$params.patient.degreeDisabilityOptions;
                                this.colOptions = options.filter(option => option.value !== null);
                                break;
                            }
                            case "agent_orange_victim":
                            {
                                let options = this.$params.patient.victimOptions;
                                this.colOptions = options.filter(option => option.value !== null);
                                break;
                            }
                            case "year_old":
                            {
                                this.colOptions = [
                                    {value: 1, text: "Trẻ em"},
                                    {value: 2, text: "Người lớn"}
                                ];
                                break;
                            }

                        }
                    }
                }, deep: true
            }
        },
        methods: {
            getLabelTable() {
                let option = this.groupOptions.find(o => o.value === this.filterFormModel.group);
                if (!option) {
                    return this.filterFormModel.group;
                }
                let label = option.text.replace("Theo", "").trim();
                return label.charAt(0).toUpperCase() + label.slice(1);
            },
            setTab(tab, parent_id) {
                this.filterFormModel.type = tab;
                this.filterFormModel.parent_id = parent_id;
                this.load();
            },
            loadGroup() {
                this.filterFormModel.cols = null;
                this.load();
            },
            load() {
                this.listLoading = true;
                this.chartLoading1 = true;
                this.chartLoading2 = true;
                this.chartLoading3 = true;
                this.chartLoading4 = true;
                this.chartLoading5 = true;
                this.chartLoading6 = true;
                this.chartLoading7 = true;
                this.chartLoading8 = true;
                let filter = Object.assign({}, this.filterFormModel);
                this.$service.get(`${this.service}/index`, {params: filter}).then(async (response) => {
                    if (response.data) {
                        this.parents = response.data.parents;
                        this.kpis = response.data.kpis;

                        if (this.filterFormModel.parent_id == null) {
                            this.filterFormModel.parent_id = this.parents[0].id;
                            this.filterFormModel.type = this.parents[0].type;
                        }
                        this.loadChart02();
                        this.loadChart03();
                        this.loadChart04();
                        this.loadChart05();

                        this.loadTable06();
                        this.loadTable07();
                        this.loadTable08();

                        await this.loadChart01();
                    }
                }).finally(() => {
                    this.firstLoading = false;
                    this.listLoading = false;
                });
            },
            async loadChart01() {
                this.chartLoading1 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                let response01 = await this.$service.get(`${this.service}/chart01_1`, {
                    params: filter
                });
                let response02 = await this.$service.get(`${this.service}/chart01_2`, {
                    params: filter
                });
                let response03 = await this.$service.get(`${this.service}/chart01_3`, {
                    params: filter
                });
                if (this.filterFormModel.group === 'all') {
                    this.chartOptions1 = {
                        colors: this.colorFixed,
                        plotOptions: this.$params.chart.plotOptions,
                        dataLabels: this.$params.chart.dataLabels,
                        fill: {
                            opacity: 1
                        },
                        grid: {
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                        },
                        xaxis: {
                            categories: ["Tổng số người hưởng lợi (đã lọc trùng)", "Phục hồi chức năng", "Chăm sóc (tổng 3 loại hình)"],
                            tooltip: {enabled: true}
                        },
                        chart: {toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                    download: true,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                                    customIcons: []
                                },
                                export: {
                                    svg: {
                                        enabled: false,
                                        filename: 'csip_chart01_' + moment().format('HHmm_DDMMYYYY'),
                                    },
                                    png: {
                                        filename: 'csip_chart01_' + moment().format('HHmm_DDMMYYYY'),
                                    }
                                },
                                autoSelected: 'zoom'
                            }, zoom: {enabled: false}, type: 'bar'},
                        tooltip: {
                            y: {
                                formatter: function (value) {
                                    return value;
                                }
                            }
                        }
                    };
                    this.chartData1 = [
                        {name: "Số lượng", type: 'bar', data: [response01.data, response02.data, response03.data]},
                        {name: "Chỉ tiêu", type: 'bar', data: [
                                Number(this.kpis['indicator27'] ? this.kpis['indicator27'] : 0),
                                Number(this.kpis['indicator03'] ? this.kpis['indicator03'] : 0),
                                Number(this.kpis['indicator05'] ? this.kpis['indicator05'] : 0),
                            ]}
                    ];
                } else {
                    let data = {};
                    for (let item of response01.data.data) {
                        if (!item.total || item.total === 0) {
                            continue;
                        }
                        if (!data[item.label]) {
                            data[item.label] = {
                                label: item.label,
                                i1: 0,
                                i2: 0,
                                i3: 0,
                            };
                        }
                        data[item.label].i1 += item.total;
                    }
                    for (let item of response02.data.data) {
                        if (!item.total || item.total === 0) {
                            continue;
                        }
                        if (!data[item.label]) {
                            data[item.label] = {
                                label: item.label,
                                i1: 0,
                                i2: 0,
                                i3: 0,
                            };
                        }
                        data[item.label].i2 += item.total;
                    }
                    for (let item of response03.data.data) {
                        if (!item.total || item.total === 0) {
                            continue;
                        }
                        if (!data[item.label]) {
                            data[item.label] = {
                                label: item.label,
                                i1: 0,
                                i2: 0,
                                i3: 0,
                            };
                        }
                        data[item.label].i3 += item.total;
                    }
                    data = Object.values(data);
                    this.chartOptions1 = {
                        colors: this.colorFixed,
                        plotOptions: this.$params.chart.plotOptions,
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [0],
                            formatter: function (val, { seriesIndex}) {
                                return val && val > 0 ? val : (seriesIndex === 0 ? 0 : '');
                            },
                            offsetY: -10,
                            style: {
                                fontSize: '12px',
                                colors: ["#222222"]
                            }
                        },
                        stroke: {
                            width: [4, 0, 0]
                        },
                        fill: {
                            opacity: 1
                        },
                        grid: {
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                        },
                        xaxis: {
                            labels: {
                                rotate: -45
                            },
                            categories: data.map(item => item.label),
                            tooltip: {enabled: true}
                        },
                        chart: {
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                    download: true,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                                    customIcons: []
                                },
                                export: {
                                    svg: {
                                        enabled: false,
                                        filename: 'csip_chart02_' + moment().format('HHmm_DDMMYYYY'),
                                    },
                                    png: {
                                        filename: 'csip_chart02_' + moment().format('HHmm_DDMMYYYY'),
                                    }
                                },
                                autoSelected: 'zoom'
                            },
                            zoom: {enabled: false},
                            type: 'bar',
                            stacked: false
                        },
                        tooltip: {
                            y: {
                                formatter: function (value) {
                                    return value;
                                }
                            }
                        }
                    };
                    this.chartData1 = [
                        {name: "Tổng số người hưởng lợi (đã lọc trùng)", type: "line", data: data.map(item => item.i1)},
                        {name: "Phục hồi chức năng", type: 'column', data: data.map(item => item.i2)},
                        {name: "Chăm sóc (Tổng 3 loại hình)", type: 'column', data: data.map(item => item.i3)},
                    ];
                }

                this.chartLoading1 = false;
            },
            loadChart02() {
                this.chartLoading2 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/chart02`, {
                    params: filter
                }).then(response => {
                    if (this.filterFormModel.group === 'all') {
                        this.chartOptions2 = {
                            colors: this.colorFixed,
                            plotOptions: this.$params.chart.plotOptions,
                            dataLabels: this.$params.chart.dataLabels,
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: [""],
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart02_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart02_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            tooltip: {
                                y: {
                                    formatter: function (value) {
                                        return value;
                                    }
                                }
                            }
                        };
                        this.chartData2 = [
                            {name: "Số lượng", type: 'bar', data: [Number(response.data)]},
                            {name: "Chỉ tiêu", type: 'bar', data: [Number(this.kpis['indicator17'] ? this.kpis['indicator17'] : 0)]}
                        ];
                    } else {
                        this.chartOptions2 = {
                            colors: this.colorFixed,
                            plotOptions: this.$params.chart.plotOptions,
                            dataLabels: this.$params.chart.dataLabels,
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: response.data.data.map(item => item.label),
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart02_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart02_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            tooltip: {
                                y: {
                                    formatter: function (value) {
                                        return value;
                                    }
                                }
                            }
                        };
                        this.chartData2 = [
                            {name: "Số lượng",data: response.data.data.map(it => it['total'] ? it['total'] : 0)},
                        ];
                    }


                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading2 = false;
                });
            },
            loadChart03() {
                this.chartLoading3 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/chart03`, {
                    params: filter
                }).then(response => {
                    if (this.filterFormModel.group === 'all') {
                        this.chartOptions3 = {
                            colors: this.colorFixed,
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val && val > 0 ? val : '';
                                },
                                offsetX: 25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#222222"]
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: [""],
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart03_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart03_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'}
                        };
                        this.chartData3 = [
                            {name: "Số lượng", type: 'bar', data: [Number(response.data)]},
                            {name: "Chỉ tiêu", type: 'bar', data: [Number(this.kpis['indicator12'] ? this.kpis['indicator12'] : 0)]}
                        ];
                    } else {
                        this.chartOptions3 = {
                            colors: this.colorFixed,
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val && val > 0 ? val : '';
                                },
                                offsetX: 25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#222222"]
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: response.data.data.map(item => item.label),
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart03_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart03_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            tooltip: {
                                y: {
                                    formatter: function (value) {
                                        return value;
                                    }
                                }
                            }
                        };
                        this.chartData3 = [
                            {name: "Số lượng",data: response.data.data.map(it => it['total'] ? it['total'] : 0)},
                        ];
                    }


                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading3 = false;
                });
            },
            loadChart04() {
                this.chartLoading4 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/chart04`, {
                    params: filter
                }).then(response => {
                    if (this.filterFormModel.group === 'all') {
                        this.chartOptions4 = {
                            colors: this.colorFixed,
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val && val > 0 ? val : '';
                                },
                                offsetX: 25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#222222"]
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: [""],
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart04_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart04_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'}
                        };
                        this.chartData4 = [
                            {name: "Số lượng", type: 'bar', data: [Number(response.data)]},
                            {name: "Chỉ tiêu", type: 'bar', data: [Number(this.kpis['indicator14'] ? this.kpis['indicator14'] : 0)]}
                        ];
                    } else {
                        this.chartOptions4 = {
                            colors: this.colorFixed,
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val && val > 0 ? val : '';
                                },
                                offsetX: 25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#222222"]
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: response.data.data.map(item => item.label),
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart03_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart03_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            tooltip: {
                                y: {
                                    formatter: function (value) {
                                        return value;
                                    }
                                }
                            }
                        };
                        this.chartData4 = [
                            { name: 'Số lượng',data: response.data.data.map(it => it['total'] ? it['total'] : 0)},
                        ];
                    }


                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading4 = false;
                });
            },
            loadChart05() {
                this.chartLoading5 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/chart05`, {
                    params: filter
                }).then(response => {
                    if (this.filterFormModel.group === 'all') {
                        this.chartOptions5 = {
                            colors: this.colorFixed,
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val && val > 0 ? val : '';
                                },
                                offsetX: 25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#222222"]
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: [""],
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart05_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart05_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'}
                        };
                        this.chartData5 = [
                            {name: "Số lượng", type: 'bar', data: [Number(response.data)]},
                            {name: "Chỉ tiêu", type: 'bar', data: [Number(this.kpis['indicator09'] ? this.kpis['indicator09'] : 0)]}
                        ];
                    } else {
                        this.chartOptions5 = {
                            colors: this.colorFixed,
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: {
                                        position: 'top'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val && val > 0 ? val : '';
                                },
                                offsetX: 25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#222222"]
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: response.data.data.map(item => item.label),
                                tooltip: {enabled: false}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        svg: {
                                            enabled: false,
                                            filename: 'csip_chart05_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_chart05_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            tooltip: {
                                y: {
                                    formatter: function (value) {
                                        return value;
                                    }
                                }
                            }
                        };
                        this.chartData5 = [
                            {name: "Số lượng",data: response.data.data.map(it => it['total'] ? it['total'] : 0)},
                        ];
                    }


                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading5 = false;
                });
            },
            loadTable06() {
                this.chartLoading6 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/table06`, {
                    params: filter
                }).then(response => {
                    this.items6 = response.data.data;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading6 = false;
                });
            },
            loadTable07() {
                this.chartLoading7 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/table07`, {
                    params: filter
                }).then(response => {
                    if(response.data.data && response.data.data.length === 1 && response.data.data[0].mau == 0 && response.data.data[0].tu == 0) {
                        this.items7 = [];
                    } else {
                        this.items7 = response.data.data;
                    }
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading7 = false;
                });
            },
            loadTable08() {
                this.chartLoading6 = true;
                let filter = Object.assign({}, this.filterFormModel);
                filter.cols = filter.cols && filter.cols.length ? filter.cols.join(",") : null;

                this.$service.get(`${this.service}/table08`, {
                    params: filter
                }).then(response => {
                    if(response.data.data && response.data.data.length === 1 && response.data.data[0].mau == 0 && response.data.data[0].tu == 0) {
                        this.items8 = [];
                    } else {
                        this.items8 = response.data.data;
                    }
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.chartLoading8 = false;
                });
            },
        },
        mounted: function () {
            this.$bus.$on("dashboardFixed", this.load);
            this.load();
        },
        beforeDestroy: function () {
            this.$bus.$off("dashboardFixed");
        },
    }
</script>